import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'searchFilter',
  pure: false
})

  export class searchFilterPipe implements PipeTransform{
      transform(produtos:any[], searchTerm: any):any[] {
if (!produtos || !searchTerm){
  return produtos;
}
return produtos.filter(produtos =>
   produtos.nome.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
      }
  }