import { NgModule } from '@angular/core';
import { searchFilterPipe } from 'src/app/pipes/search.pipe';

@NgModule({
  declarations: [ 
    searchFilterPipe
  ],
  exports: [
    searchFilterPipe
  ]
})
export class SharedPipesModule {}